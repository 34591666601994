<template>
  <div class="pos-r success-container">
    <div class="pos-a content px-4 h-flex al-c">
      <img src="/img/svg/success/success.svg" alt="" />
      <div class="fz-14 ta-c">
        <div>
          Deposit successful, and more details can be viewed on 4EVERLAND.
        </div>
        <div class="mt-1" style="word-break: break-all">
          Tx Hash:
          <a :href="txHashLink" target="__blank">{{ cutStr(txHash, 4, 4) }}</a>
        </div>
      </div>
      <div class="mt-6">
        <v-btn color="primary" class="fw-b" @click="handleOpen"
          >Go to dashboard for use</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { cutStr } from "@/utils";
export default {
  data() {
    return {
      txHash: "",
    };
  },
  created() {
    this.txHash = this.$route.params.txHash;
  },
  computed: {
    txHashLink() {
      return "https://scrollscan.com/tx/" + this.txHash;
    },
  },
  methods: {
    cutStr,
    handleOpen() {
      window.open("https://dashboard.4everland.org");
    },
  },
};
</script>

<style lang="scss" scoped>
.success-container {
  height: 100%;
  background: #fff;
}
.content {
  width: 100%;
  left: 50%;
  top: 25%;
  transform: translate(-50%);
}
</style>

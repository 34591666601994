export function cutStr(text, pre = 100, trail) {
  if (typeof text == "number") {
    text = text.toString();
  }

  if (trail ? text.length < pre + 3 + trail : text.length < pre + 3) {
    return text;
  }
  const preText = text.substring(0, pre);
  let trailText = "";
  if (trail) {
    trailText = text.substring(text.length - trail);
  }
  return preText + "..." + trailText;
}
